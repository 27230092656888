import { render, staticRenderFns } from "./viewPostItem.vue?vue&type=template&id=8e81fee2&scoped=true"
import script from "./viewPostItem.vue?vue&type=script&lang=js"
export * from "./viewPostItem.vue?vue&type=script&lang=js"
import style0 from "./viewPostItem.vue?vue&type=style&index=0&id=8e81fee2&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e81fee2",
  null
  
)

export default component.exports